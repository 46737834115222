import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql, PageProps } from 'gatsby'

import { SettingYaml } from '../graphql'
import { SEOWidget as SEO } from '../widgets/SEOWidget'

const title = '404 NOT FOUND: お探しのページは見つかりません'
type Props = PageProps<{ settingYaml: SettingYaml }>
function NotFoundPage(props: Props) {
  const fallbackUrl =
    props && props.data && props.data.settingYaml && props.data.settingYaml.fallbackUrl
  React.useEffect(() => {
    const i = setTimeout(() => {
      if (!fallbackUrl) {
        return
      }
      if (typeof window === 'undefined') {
        return
      }
      window.location.href = fallbackUrl
    }, 1200)
    return () => clearTimeout(i)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {fallbackUrl && (
        <Helmet>
          <meta httpEquiv="refresh" content={`1;URL=${fallbackUrl}`} />
        </Helmet>
      )}
      <SEO title={title} />
      <>
        <div className="faq-header">
          <h2>{title}</h2>
        </div>
        <div className="faq-panel">
          <h3 className="faq-panel-title">
            一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。
          </h3>
        </div>
      </>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundData {
    settingYaml(ignore: { ne: true }) {
      fallbackUrl
    }
  }
`
